<template>
  <div class="row">
    <div class="col-xl-3 col-lg-3 col-md-2 col-12"></div>
    <div class="col-xl-6 col-lg-6 col-md-8 col-12">
      <h3>View Voucher</h3>
      <form class="ls-form">
        <div class="form-group">
          <label for="id">Voucher ID</label>
          <input
            type="text"
            class="form-control"
            id="id"
            v-model="voucher.receiver_full_name"
          />
        </div>
        <div class="form-group">
          <label for="orderNumber">Voucher Order Number</label>
          <input
            type="text"
            class="form-control"
            id="orderNumber"
            v-model="voucher.receiver_full_name"
          />
        </div>
        <div class="form-group">
          <label for="amount">Voucher Amount</label>
          <input
            type="phone"
            class="form-control"
            id="amount"
            v-model="voucher.receiver_mobile"
          />
        </div>
        <div class="form-group">
          <label for="date">Email address of Receiver</label>
          <input
            type="email"
            class="form-control"
            id="date"
            aria-describedby="emailHelp"
            v-model="voucher.email"
          />
        </div>

        <button
          v-if="auth.isAuthenticated"
          :disabled="isLoading"
          type="submit"
          class="btn btn-primary ls-submit-button"
          @click.prevent="createVoucherRequest()"
        >
          {{ isLoading ? "Processing..." : "Submit" }}
          <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
          <div
            v-if="isLoading"
            class="spinner-border text-light"
            role="status"
          ></div>
        </button>
        <router-link v-else :to="{ name: 'login' }">
          <button type="submit" class="btn btn-primary ls-submit-button">
            Add to Cart
          </button>
        </router-link>
      </form>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-2 col-12"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "Voucher",
  data() {
    return {
      voucher: {
        voucher_id: "",
        receiver_mobile: "",
        receiver_full_name: "",
        email: "",
      },
      voucherOptions: [],
      isLoading: false,
      voucherShop: "",
      voucherShops: [],
      filteredVouchers: [],
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {
    this.getVoucherOptions();
  },
  methods: {
    getVoucherOptions() {
      axios.get("/vouchers").then((response) => {
        //console.log("Vouchers : " + JSON.stringify(response.data));
        response.data.forEach((item) => {
          if (item.status == "active") {
            this.voucherOptions.push(item);
          }
        });

        //Collecting Voucher Types : Leo
        var voucherShopTemp = {};
        this.voucherShops.length = 0;
        for (let i = 0; i < this.voucherOptions.length; i++) {
          voucherShopTemp[this.voucherOptions[i].name] = this.voucherOptions[
            i
          ].name;
        }
        for (let key in voucherShopTemp) {
          this.voucherShops.push(key);
        }
        console.log(JSON.stringify(this.voucherShops));

        this.voucherShop = this.voucherShops[0];
        // this.voucherOptions = response.data;
        //this.voucher.voucher_id = this.voucherOptions[0].id;

        this.filteredVouchers.length = 0;
        for (let i = 0; i < this.voucherOptions.length; i++) {
          if (this.voucherOptions[i].name == this.voucherShop) {
            this.filteredVouchers.push(this.voucherOptions[i]);
          }
        }
        this.voucher.voucher_id = this.filteredVouchers[0].id;
      });
    },

    getFilteredVouchers(event) {
      this.key = event.target.value;
      this.filteredVouchers.length = 0;
      for (let i = 0; i < this.voucherOptions.length; i++) {
        if (this.voucherOptions[i].name == this.key) {
          this.filteredVouchers.push(this.voucherOptions[i]);
        }
      }

      this.voucher.voucher_id = this.filteredVouchers[0].id;
    },

    createVoucherRequest() {
      this.isLoading = true;

      axios
        .post("/buy_vouchers", this.voucher)
        .then((response) => {
          if (response.data.id) {
            this.addToCart(response.data.id);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.$toasted.error("Voucher Creation failed, please try again");
          this.isLoading = false;
        });
    },

    addToCart(transId) {
      let price = 0;
      let voucherName = "";
      this.voucherOptions.forEach((item) => {
        if (item.id == this.voucher.voucher_id) {
          price = item.amount;
          voucherName = item.name;
        }
      });
      const quantity = 1;
      const product = {
        id: transId,
        name: voucherName,
        quantity: quantity,
        price: price,
        total: price * quantity,
        type: "voucher",
        shop: 2,
      };

      console.log("Voucher");
      console.log(product);
      this.$store.dispatch("cart/addToCart", {
        product: product,
        quantity: 1,
      });

      this.$toasted.success("Voucher added to cart");
    },
  },
};
</script>
